import styled from '@emotion/styled';


export default styled.div`
        border-color: #ff2d2d;
    border-bottom: solid 0.5px #ff2d2d;
    border-right: none;
    border-left: none;
    border-top: 0px;
    width: 90%;
    height: 2px;
    margin: 0 5% 0 5%;


`