import React, { useReducer } from 'react'

import {
    SET_ACTIVE_PAGE, SET_ANUNCIOS
} from '../../types';

import sessionContext from './sessionContext';

import sessionReducer from './sessionReducer';
import anuncios from '../../data/anuncios'

const SessionState = props => {


    //Inicialización del state
    const initialState = {
        activePage: "inicio",
        anuncios: []
    }


    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(sessionReducer, initialState);

    //Funciones para modificar el state de sesion
    const setActivePage = (newPage) => {
        dispatch(
            {
                type: SET_ACTIVE_PAGE,
                payload: newPage
            }
        )
    }

    const setAnuncios = (anuncios) => {

        dispatch(
            {
                type: SET_ANUNCIOS,
                payload: anuncios
            }
        )

    }


    return (
        <sessionContext.Provider

            value={{
                activePage: state.activePage,
                anuncios: state.anuncios,

                setActivePage,
                setAnuncios
            }}

        >
            {props.children}
        </sessionContext.Provider>
    )
}

export default SessionState;