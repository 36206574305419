import React from 'react';
import styled from '@emotion/styled';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import history from './history'
//Import de componentes
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

import Inicio from './components/Inicio/Inicio';
import Discrecionales from './components/Discrecionales/Discrecionales';

import Pedanias from './components/Pedanias/Pedanias';
import PedaniasTabs from './components/Pedanias/PedaniasTabs';

import Contacto from './components/Contacto/Contacto'


//Import de context
import SessionState from './context/session/sessionState';


//

//import de fa-icons library

import { library } from '@fortawesome/fontawesome-svg-core'
import {faWhatsapp} from '@fortawesome/fontawesome-free-brands'
import { faCaretRight, faCaretLeft, faPhone, faEnvelope, faBuilding, faClock, faFileSignature,faMapMarkerAlt, faInfoCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import CookieBannerComponent from './components/layout/CookieBanner';
//Libreria global a la aplicacion a la que incluir los iconos que se utilizarán
library.add(faCaretRight, faCaretLeft, faPhone, faEnvelope, faBuilding, faClock, faWhatsapp ,faMapMarkerAlt, faFileSignature, faInfoCircle, faCircle, faCheckCircle, faSpinner)

function App() {
  return (
    <SessionState>
      <Router history={history}>
        <Navbar

        />
        
    <Switch>
    
        <Route exact path="/" component={Inicio} />
        <Route exact path="/inicio" component={Inicio} />
        <Route exact path="/pedanias" component={PedaniasTabs} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/discrecionales" component={Discrecionales} />

        {/*
        <Route exact path="/pedanias" component={Configuracion} />
        
        <Route exact path="/nuevoPresupuesto" component={NuevoPresupuesto} />
        <Route exact path="/historialPresupuestos" component={HistorialPresupuestos} />
        <Route exact path="/presupuesto" component={Presupuesto} />
        */}
    </Switch>
    

      </Router>
      <CookieBannerComponent/>
      <Footer/>
    </SessionState>
    
  );
}

export default App;

/*
contactowebvalenciana@gmail.com
autoslavalenciana
*/