import React, { useState } from 'react'
import MenuTarjetas from './recargaTarjetas/MenuTarjetas'
import PuntosRecarga from './recargaTarjetas/PuntosRecarga'
import { useWindowSize } from '../../commons/utils'
import MenuTarjetasCollapsable from './recargaTarjetas/MenuTarjetasCollapsable'
import ContentInfoTarjetasFullWidth from './recargaTarjetas/ContentInfoTarjetasFullWidth'


const RecargaTarjetas = () => {

    const size = useWindowSize()
    const [windowSize, setwindowSize] = useState(size)
    const [tarjetaSeleccionada, settarjetaSeleccionada] = useState("tarjetaVeinte")

    return (
        <>
            <div className="row message-table-row  mt-5 " >
                {
                    windowSize.width > 768

                        ?
                        <MenuTarjetas tarjetaSeleccionada={tarjetaSeleccionada} settarjetaSeleccionada={settarjetaSeleccionada} />

                        :
                        <MenuTarjetasCollapsable />
                }

                <PuntosRecarga />


            </div>
            {
                windowSize.width > 768

                    ?
                    <div className="row message-table-row justify-content-center mt-1 " >

                        <ContentInfoTarjetasFullWidth id={tarjetaSeleccionada} />
                    </div>

                    :
                    null
            }
        </>
    )
}

export default RecargaTarjetas
