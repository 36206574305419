import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { postMetric } from '../../webInstrumentation/metrcisControllers'

const BotonDescargarHorarios = ({pdf, activeitem}) => {
    return (
        
            <a 
            className="btn btn-primary mt-3 barra-botones "
            type="button"
            onClick={
                () => {
                    postMetric.counter({
                        metric: "descargar_horario",
                        value: 1,
                        labels: {"linea": activeitem},
                        operation: "inc"
                    })
                }
            }
            href={pdf}
            target="_blank"
            style={{
                marginLeft: "6%"
            }}
            
            ><FontAwesomeIcon className="mr-2 ml-2 clickable"  icon="clock"  />Ver horarios</a>
        
    )
}

export default BotonDescargarHorarios
