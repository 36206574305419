import { webInstrumentationClient } from './axiosClient'
const ENABLE = true

export const postMetric = {
    gauge: (metricBody) =>{
        if(ENABLE)
        webInstrumentationClient.post("/gauge", metricBody)
    },
    counter:  (metricBody) =>{
        if(ENABLE)
        webInstrumentationClient.post("/counter", metricBody)
    },
    histogram:  (metricBody) =>{
        if(ENABLE)
        webInstrumentationClient.post("/histogram", metricBody)
    },
    summary:  (metricBody) =>{
        if(ENABLE)
        webInstrumentationClient.post("/summary", metricBody)
    },
}

