import React from 'react'
const Anuncio = ({ anuncio }) => {


    const { mensaje, fechaPublicacion } = anuncio



    return (
        <div className="ml-3 anuncio-texto" style={{ overflowY: "auto" }}>

            <i>Fecha de publicación: {fechaPublicacion.stringValue}</i>
            <hr />
          
            <div
                dangerouslySetInnerHTML={{
                    __html: mensaje.stringValue
                }}></div>
        </div>
    )
}

export default Anuncio
