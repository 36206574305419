import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';



const Contacto = () => {

    //estado para mostrar mensaje de copiado
    const [copiado, setcopiado] = useState(false)

    //state para los campos de formulario
    const [formulario, setformulario] = useState({
        email: "",
        movil: "",
        whatsapp: "",
        texto: ""
    })


    //state de error de formulario
    const [error, seterror] = useState(null)
    //state de confirmacion de formulario
    const [confirmacion, setconfirmacion] = useState(null)


    useEffect(() => {
        if (copiado) {
            setInterval(() => {
                setcopiado(false)
            }, 5000);
        }
    }, [copiado])

    const changeFormulario = (e) => {
        setformulario({
            ...formulario,
            [e.target.name]: e.target.value
        })
    }
    


    const enviarContacto = async e => {

        e.preventDefault();

        //validamos formulario 
        if (formulario.email.trim() === "" &&
            formulario.movil.trim() === "" &&
            formulario.texto.trim() === "") {
            seterror("Por favor, debes rellenar los campos del formulario.");
            return;
        }
         if (formulario.email.trim() === "" &&
            formulario.movil.trim() === "") {
            seterror("Dinos donde quieres que te contactemos, introduce un email o un teléfono.")
            return;

        }
        if (formulario.texto.trim() === "") {
            seterror("¿Cual es tu consulta? Por favor, rellena el campo contandonos lo que necesitas.");
            return;
        }
        seterror(null);

        //enviando email
        const response = await axios({
            method:"post",
            url: "https://us-central1-autos-la-valenciana.cloudfunctions.net/func-enviar-mail-contacto", 
           headers:{
               'Content-Type': "text/plain"
           },
            data: JSON.stringify(formulario)});

        //mensaje de confirmacion
        if (response.status === 200) {
            //console.log(response)
            setconfirmacion("¡Listo! Tu mensaje se ha enviado correctamente, nos pondremos en contacto contigo tan pronto como sea posible. Gracias.")
            setformulario({
                email: "",
                movil: "",
                whatsapp: "",
                texto: ""
            })
        }
        else if(response.status === 500) {
            seterror("¡Vaya! Ha habido un error al enviar tu consulta. Prueba de nuevo más tarde o ponte en contacto a través del teléfono o el email de atención al cliente. Disculpa las molestias :( .")
        }
        return;
    }

    return (
        <div className=" main-page">
            <div className="row message-table-row h-100 justify-content-md-center" >
                <div className="col-lg-6  mt-5 mb-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <h3>¿Cómo podemos ayudarte?</h3>
                        </div>
                        <div className="card-body">
                            <p>Contacta con nosotros a través del teléfono <i>956 75 29 09</i>, o envianos un correo a
                            <CopyToClipboard text={"info@grupoempresarialandaluz.com"}
                                    onCopy={() => setcopiado(true)}>
                                    <u className="clickable"> info@grupoempresarialandaluz.com</u>
                                </CopyToClipboard></p>
                            {
                                copiado
                                    ?
                                    <div className="alert alert-success d-inline-block" role="alert">
                                        ¡Lo hemos puesto en el portapapeles :) !
                                    </div>
                                    :
                                    null
                            }


                            <hr />
                            <strong>Si lo deseas, puedes enviarnos tu consulta directamente:</strong>
                            <form

                                onSubmit={enviarContacto}>
                                <p>Dejanos tus datos para que podamos responderte</p>
                                <div className="form-group">
                                    <label htmlFor="email">Correo electrónico (opcional)</label>
                                    <input
                                        value={formulario.email}
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        id="email" placeholder=""
                                        onChange={changeFormulario} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="movil">Teléfono Móvil (opcional)</label>
                                    <input
                                        type="text"
                                        value={formulario.movil}

                                        className="form-control"
                                        name="movil"
                                        id="movil"
                                        placeholder=""
                                        onChange={changeFormulario} />
                                </div>
                                <div className="form-check">


                                    <input
                                        className="form-check-input"
                                        name="whatsapp"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                        checked={formulario.whatsapp}

                                        onChange={(e) => {
                                            setformulario({
                                                ...formulario,
                                                [e.target.name]: e.target.checked
                                            })
                                        }} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Marca esta casilla si prefieres que te contactemos por WhatsApp
                                        <FontAwesomeIcon icon={['fab', 'whatsapp']} style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
                                    </label>
                                </div>
                                <p> Cuentanos qué necesitas:</p>
                                <textarea
                                    className="form-control mt-2"
                                    rows="10"
                                    cols="50"
                                    name="texto"
                                    onChange={changeFormulario}
                                    value={formulario.texto}


                                ></textarea>
                                {
                                    error
                                        ?
                                        <div className="alert alert-danger mt-2" role="alert">
                                            {error}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    confirmacion
                                        ?
                                        <div className="alert alert-success mt-2" role="alert">
                                            {confirmacion}
                                        </div>
                                        :
                                        null
                                }
                                <button className="btn btn-primary btn-block mt-3"><FontAwesomeIcon icon="envelope" className="mr-2" />Contacta</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Contacto
