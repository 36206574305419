import React, { useState, useEffect, useContext } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'


import TittleHr from '../../commons/TittleHr'
import DatesSelector from './DatesSelector'

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StepsProgression from './StepsProgression';
const libraries = ['places'];

const Step3 = ({ }) => {


    const formularioContext = useContext(formularioDisContext)

    const { start_point, end_point } = formularioContext

    const initialCenter = start_point ? start_point.coordinates : {
        lat: 36.6850064,
        lng: -6.126074399999999
    }



    const [center, setcenter] = useState(initialCenter)


    const [zoom, setzoom] = useState(6)




    return (

        <div>
            <h4 className="ml-3 mt-3 mb-3">Paso 3: Introduce los horarios</h4>
            <TittleHr />
            <h5 className="mt-5 ml-5 mb-5">Introduce la fecha y la hora de ida y de vuelta</h5>
            <div className="row  h-100 justify-content-md-center">

                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w"
                    libraries={libraries}
                >
                    <div className="col-sm-4">

                        <div className="row mt-2">
                            <DatesSelector />

                        </div>

                        <StepsProgression />
                    </div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-6">
                        <GoogleMap
                            mapContainerStyle={{
                                height: "500px",
                                width: "100%"
                            }}
                            zoom={zoom}
                            center={center}
                        >

                            {
                                start_point
                                    ?
                                    <Marker
                                        position={start_point.coordinates}
                                    />
                                    :
                                    null
                            }
                            {
                                end_point
                                    ?
                                    <Marker
                                        position={end_point.coordinates}
                                    />
                                    :
                                    null
                            }
                        </GoogleMap>

                    </div>
                </LoadScript>

            </div>
        </div>
    )
}

export default Step3
