const puntosRecarga = {

    jerez: {
        lng: -6.132642, lat: 36.680799
    },
    barca: {
        lng: -6.021049, lat: 36.676836
    },
    isidro: {
        lng: -5.988651, lat: 36.643699
    },
    torno: {
        lng: -5.969393, lat: 36.619574
    },
    gibalbin: {
        lng:  -5.924286, lat: 36.827899
    },
    torrecera:{
        lng:  -5.943240, lat: 36.609598
    }
}

export default puntosRecarga