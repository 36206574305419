import React, { useContext, useEffect } from 'react'
import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StepsIndicators from './StepsIndicators';
import ReactTooltip from "react-tooltip";

const StepsProgression = () => {

    const formularioContext = useContext(formularioDisContext)


    

    const { start_point, end_point, stepProgression,
        current_step, nextStep, previusStep,
        completeStep, start_date, end_date,
        add_info } = formularioContext


    
    //Al hacer click en paso siguiente, comprueba, segun el paso que sea que los datos están completos
    //y ejecuta el dispatch de completar step
    const checkStepAndComplete = () => {


        switch (current_step) {

            //Si es el primer paso, se comprueba que el parámetro start_point no es null
            case 1:
                if (start_point !== null) {
                    completeStep()
                }
            //segundo paso, se comprueba que el parámetro end_point no es null
            case 2:
                if (end_point !== null) {
                    completeStep()
                }
            //tercer paso, se comprueba que ambas fechas estan completadas
            case 3:
                if (start_date !== null && end_date !== null) {
                    completeStep()
                }
            //cuarto paso se comprueba que se han rellenado los campos num_pasajeros y num_pasajeros_12
            case 4:

                if (add_info.num_pasajeros !== "" && add_info.num_pasajeros !== "") {
                        
                    completeStep();
                    /* Si aun no se han completado todos los pasos, no se habilitará el boton de finalizar*/
                    if(stepProgression.reduce((acc, item) => acc + item, 0) == 4  ){
                        nextStep() 
                    }
                }

        }

    }

    return (

        <div>
            {
                current_step === 1

                    ?
                    <div className="mt-5 ml-5">
                        <StepsIndicators />
                        <button className="btn btn-primary  "
                            onClick={() => { checkStepAndComplete(); nextStep() }}
                        >
                            Paso {current_step + 1}<FontAwesomeIcon icon="caret-right" className="ml-2" /></button>


                    </div>
                    :
                    null
            }
            {
                (current_step !== 1 && current_step !== 4)

                    ?
                    <div className="mt-5">
                        <button className="btn btn-primary  "
                            onClick={() => previusStep()}
                        >
                            <FontAwesomeIcon icon="caret-left" className="mr-2" />Paso {current_step - 1}</button>

                        <StepsIndicators />
                        <button className="btn btn-primary  "
                            onClick={() => { checkStepAndComplete(); nextStep() }}
                        >
                            Paso {current_step + 1}  <FontAwesomeIcon icon="caret-right" className="ml-2" /> </button>
                    </div>
                    :
                    null

            }

            {

                current_step === 4

                    ?
                    <div className="mt-5">
                        <button className="btn btn-primary  "
                            onClick={() => previusStep()}
                        >
                            <FontAwesomeIcon icon="caret-left" className="mr-2" />Paso {current_step - 1}</button>

                        <StepsIndicators />
                        <span data-tip="Debes completar todos los pasos para finalizar">
                            <button className="btn btn-primary  "
                            

                                onClick={() => { checkStepAndComplete()}} >

                                Finalizar
                            <FontAwesomeIcon icon="caret-right" className="ml-2" /> </button>
                        </span>
                        <ReactTooltip place="top" type="dark" effect="solid" />

    
                        


                    </div>
                    :
                    null
            }





        </div>
    )
}

export default StepsProgression
