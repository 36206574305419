import React, { Fragment, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import sessionContext from '../../context/session/sessionContext';

//TODO: Mejorar estilos

const Navbar = () => {
  let history = useHistory();

  const _sessionContext = useContext(sessionContext)


  const { activePage, setActivePage }  = _sessionContext
  

  return (
    <Fragment>
      <div id="brandDiv">
      <img src={process.env.PUBLIC_URL + '/gea.png'} onClick={()=>{
                  setActivePage('inicio')
                    history.push('/inicio')
      }} className="clickable" style={{marginLeft: "2rem", "height": "77px", "marginTop": "10px"}} height="100"/> 
      </div>
      <nav id="navbar" className="navbar navbar-expand-lg navbar-light bg-light shadow ">
      
          <ul className="navbar-nav mr-auto ml-sm-5">
            <li className={activePage === "inicio" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" onClick={()=>setActivePage("inicio")} to="/">Inicio</Link>
            </li>
            <li className={activePage === "pedanias" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" onClick={()=>setActivePage("pedanias")}   to="/pedanias">Pedanias</Link>
            </li>
            <li className={activePage === "discrecionales" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" onClick={()=>setActivePage("discrecionales")}   to="/discrecionales">Discrecionales</Link>
            </li>
            <li className={activePage === "contacto" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" onClick={()=>setActivePage("contacto")}   to="/contacto">Contacto</Link>
            </li>
          </ul>


       
      </nav>
    </Fragment>
  )
}

export default Navbar
