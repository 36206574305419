import axios from 'axios'



const createWebInstrumentationClient = () =>{

    return axios.create({
        baseURL: "https://analytics.autolavalenciana.com/instrumentation/api/v1/control",
        timeout: 1000
    })
}

export const webInstrumentationClient = createWebInstrumentationClient()