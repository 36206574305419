import React, { useContext, useState } from 'react'

import FormularioPresupuesto from './FormularioPresupuesto'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '@emotion/styled';

//Import de context
import FormularioDisState from '../../context/formularioDiscrecionales/formularioDisState';

//Import web-metrics-client
import { postMetric } from '../../webInstrumentation/metrcisControllers'

const Hr = styled.div`
        border-color: #ff2d2d;
    border-bottom: solid 0.5px #ff2d2d;
    border-right: none;
    border-left: none;
    border-top: 0px;
    width: 90%;
    height: 2px;
    margin: 0 5% 0 5%;


`

const Discrecionales = () => {


    const handleClick = () => {

        postMetric.counter({
            metric: "inicio_solicitud_presupuesto",
            value: 1,
            operation: "inc"
        })
        setdesplegarFormulario(true)
    }

    const [desplegarFormulario, setdesplegarFormulario] = useState(false)

    return (
        <FormularioDisState>
            <div className=" main-page">
                <div className="row message-table-row h-100 justify-content-md-center" >
                    <div className="col-sm-11 mt-3 mb-3">
                        {
                            desplegarFormulario

                                ?
                                <>
                                    <FormularioPresupuesto 
                                    
                                        setdesplegarFormulario={setdesplegarFormulario}
                                    />
                                </>
                                :

                                <div className="card shadow-sm h-100 p-2">
                                    <h2 className="mt-3 ml-3">Servicios discrecionales</h2>
                                    <Hr className="mt-2 mb-4" />
                                    <h4 className="ml-3 mb-3"><i>Excursiones escolares, viajes a la playa, salidas de fin de semana, fiestas y eventos...</i> </h4>
                                    <h5 className="ml-3 mr-3 mb-4">Con Auto la Valenciana podrás contratar tu autobús seleccionando el punto y la hora de recogida y de destino. Rellena nuestro formulario online y te enviaremos un presupuesto</h5>
                                    <div className="row justify-content-md-center mb-4">
                                        <div className="col-sm-4">
                                            <button className="btn btn-primary btn-block mt-3"

                                                onClick={() => handleClick()}
                                            >

                                                <FontAwesomeIcon icon="file-signature" className="mr-2" />Solicitar mi presupuesto</button>

                                        </div>
                                    </div>

                                </div>
                        }
                    </div>


                </div>
            </div>
        </FormularioDisState>
    )
}

export default Discrecionales
