import React, { useState } from 'react'
import tarjetas from '../data/tarjetas'

const ContentInfoTarjeta = ({id}) => {

    
    const [content, setcontent] = useState(tarjetas[id])

    const { descripcion, beneficiarios, costes, precioRecarga, documentacion} = content
    

    return (
        <div className="content-tarjeta">
            {descripcion}
            <p className="seccion-tarjeta">Beneficiarios</p>
            <p className="info-tarjeta">{beneficiarios ? beneficiarios : "Cualquier viajero sin restricciones de edad ni condición"}</p>
            <p className="seccion-tarjeta">Tasa de alta:</p>
            <p className="info-tarjeta">{costes ? costes : "Sin tasas de alta"}</p>
            <p className="seccion-tarjeta">Precio de la recarga:</p>
            <p className="info-tarjeta">{precioRecarga}</p>
            <p className="seccion-tarjeta">Documentación a presentar:</p>
            
                <ul>
                {
                    documentacion && documentacion.length > 0

                    ? 
                    documentacion.map( (doc, index)=>(
                        <li key={index}>{doc}</li>
                    )

                    )
                    :
                    "No es necesario presentar ningun tipo de documentación"
                }
                </ul>
            

        </div>
    )
}

export default ContentInfoTarjeta
