import React from 'react'
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const FooterDiv = styled.div`
    max-height: 20rem;
  float: right;
  margin-top:20px;
  

`
const InfoDiv = styled.div`
    

`
//TODO: REordenar info footer

const Footer = () => {
    return (
        <footer>

            
            <div className="m-3">
                <div>
                    <FontAwesomeIcon icon="phone" className="mr-2" /> <p className="d-inline-block mb-0">Información: 609527875 - 956752909</p><br />
                </div>
                <div>
                    <FontAwesomeIcon icon="envelope" className="mr-2" /> <p className="d-inline-block mb-0">info@grupoempresarialandaluz.com</p><br />
                </div>
                <div>
                    <FontAwesomeIcon icon="building" className="mr-2" /> <a target="_blank" href="https://www.google.es/maps/place/Empresa+Auto+la+Valenciana+S.A./@36.6806052,-6.1596823,17z/data=!3m1!4b1!4m5!3m4!1s0xd0de939ce41cb1d:0x6aa7950727dc195e!8m2!3d36.6806052!4d-6.1574936?hl=es" className="d-inline-block mb-0">Oficina Central (Google Maps)</a>
                </div>
                <div>
                <p className="d-inline-block mb-0 mt-2">Grupo Empresarial Andaluz, 2023</p><br />

                </div>
            </div>
            
        </footer>
    )
}

export default Footer
