import React, { useState, useContext } from 'react'
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api'
import styled from '@emotion/styled'

import formularioDisContext from '../../../../context/formularioDiscrecionales/formularioDisContext';


const Input = styled.input`

    width: 100%;
    height: 3rem;
    border-bottom: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 0.5px red;


`



const AutocompleteInput = ({ place, setplace }) => {

    const [autocomplete, setautocomplete] = useState(null)


    const formularioContext = useContext(formularioDisContext)

    const { start_point, setEndPoint, current_step, end_point, nextStep } = formularioContext



    let value;
    let placeHolder = ""

    if (current_step === 1) {
        value = start_point !== null && start_point.dir_object !== null ? start_point.dir_object.formatted_address : null;
        placeHolder = "Punto de recogida"
    }
    else if (current_step === 2) {
        value = end_point !== null && end_point.dir_object !== null ? end_point.dir_object.formatted_address : null;
        placeHolder = "Punto de llegada"

    }

    const onLoad = (autocomplete) => {
       // console.log('autocomplete: ', autocomplete)
       // console.log("onload")
        setautocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            setplace({
                dir_object: autocomplete.getPlace(),
                coordinates: {
                    lat: autocomplete.getPlace().geometry.location.lat(),
                    lng: autocomplete.getPlace().geometry.location.lng(),
                }
            })
           // console.log(autocomplete)
        } else {
           // console.log('Autocomplete is not loaded yet!')
        }
    }
    return (
        <div>
            {
                place == null
                    ?

                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <Input
                            type="text"
                            placeholder={placeHolder}
                            defaultValue={start_point || end_point ? value : null}
                        />
                    </Autocomplete>
                    : null
            }
            {
                place !== null

                    ?
                    <Input
                        type="text"
                        placeholder={placeHolder}
                        defaultValue={start_point || end_point ? value : null}
                    />
                    :

                    null
            }

        </div>
    )
}

export default AutocompleteInput
