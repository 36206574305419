import React, { useState, useEffect, useContext } from 'react'
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api'
import { useHistory } from "react-router-dom";
import TittleHr from '../../commons/TittleHr'
import axios from 'axios';

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';
import sessionContext from '../../../context/session/sessionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactDataForm from './ContactDataForm';
import JourneySumup from './JourneySumup';

import { postMetric } from '../../../webInstrumentation/metrcisControllers'

const libraries = ['places', 'directions'];

const Step5 = ({ }) => {

    let history = useHistory();

    const _sessionContext = useContext(sessionContext)

    const { setActivePage } = _sessionContext

    const formularioContext = useContext(formularioDisContext)

    const { start_point, end_point, journey_info, setJourney_info, contact_data, start_date,
        end_date,
        add_info, cancelFormulario } = formularioContext

    const [dir_response, setdir_response] = useState(null)
    const initialCenter = start_point ? start_point.coordinates : {
        lat: 36.6850064,
        lng: -6.126074399999999
    }

    const directionsCallback = (response) => {
        //console.log(response)


        if (response !== null && response !== undefined) {

            if (response.status === 'OK') {
                setdir_response(
                    () => ({
                        response
                    })
                )

            } else {
                //console.log('response: ', response)
            }
        }

    }

    useEffect(() => {

        if (dir_response !== null) {
            setJourney_info({
                distance: dir_response.response.routes[0].legs[0].distance.text,
                duration: dir_response.response.routes[0].legs[0].duration.text
            })
        }


    }, [dir_response])

    const [center, setcenter] = useState(initialCenter)


    const [zoom, setzoom] = useState(6)
    //TODO:
    //funcion para ejecutar la petición de solicitude de presupuesto
    //state de error de formulario
    const [error, seterror] = useState(null)
    //state de confirmacion de formulario
    const [confirmacion, setconfirmacion] = useState(null)
    const solicitarPresupuesto = async () => {


        const { email, movil, isWhatsapp } = contact_data;
        //validación de los campos de contacto obligatorios
        if (email.trim() === "") {
            seterror("Por favor, introduzca un correo electrónico de contacto")
            return;
        }
        try {
            //enviando email
            const response = await axios({
                method: "post",
                url: "https://us-central1-autos-la-valenciana.cloudfunctions.net/solicitud-discrecional",
                headers: {
                    'Content-Type': "text/plain"
                },
                data: JSON.stringify({
                    start_point,
                    end_point,
                    start_date,
                    end_date,
                    add_info,
                    journey_info,
                    contact_data
                })
            });
            //console.log(response)
            //mensaje de confirmacion
            if (response.status === 200) {
                // console.log(response)


                setconfirmacion("¡Listo! Tu mensaje se ha enviado correctamente, nos pondremos en contacto contigo tan pronto como sea posible. Gracias. Le estamos redirigiendo a la página principal")
                setTimeout(() => {
                    cancelFormulario()
                    setActivePage('inicio')
                    history.push('/inicio')

                }, 5000);

            }
        }
        catch (e) {

            seterror("¡Vaya! Ha habido un error al enviar tu consulta. Prueba de nuevo más tarde o ponte en contacto a través del teléfono o el email de atención al cliente. Disculpa las molestias :( .")

        }

        return;


    }


    return (

        <div>
            <h4 className="ml-3 mt-3 mb-3">Paso 5: Introduce tus datos de contacto</h4>
            <TittleHr />
            <h5 className="mt-5 ml-5 mb-5">Revisa que todo este correcto y facilitanos tus datos de contacto para que te enviemos el presupuesto tan pronto como no sea posible</h5>
            <div className="row  h-100 justify-content-md-center">

                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w"
                    libraries={libraries}
                >
                    <div className="col-sm-4">

                        <div className="row">
                            <ContactDataForm />
                        </div>
                        {
                            journey_info !== null
                                ?
                                <div className="row">
                                    <JourneySumup />
                                </div>
                                :
                                null
                        }





                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-6">
                        <GoogleMap
                            mapContainerStyle={{
                                height: "500px",
                                width: "100%"
                            }}
                            zoom={zoom}
                            center={center}
                        >

                            {
                                start_point
                                    ?
                                    <Marker
                                        position={start_point.coordinates}
                                    />
                                    :
                                    null
                            }
                            {
                                end_point
                                    ?
                                    <Marker
                                        position={end_point.coordinates}
                                    />
                                    :
                                    null
                            }
                            {
                                dir_response === null

                                    ?

                                    <DirectionsService
                                        // required
                                        options={{
                                            destination: end_point.coordinates,
                                            origin: start_point.coordinates,
                                            travelMode: "DRIVING"
                                        }}
                                        // required
                                        callback={(result) => directionsCallback(result)}
                                    /*onLoad={setdir_response => {
                                        setdir_response(directionsService)
                                        console.log(directionsService.route())
                                    }}*/
                                    />
                                    :

                                    null
                            }
                            {
                                dir_response !== null

                                    ?
                                    <DirectionsRenderer
                                        // required
                                        options={{
                                            directions: dir_response.response
                                        }}

                                    />
                                    :
                                    null

                            }


                        </GoogleMap>
                        <button className="btn btn-primary btn-block mt-5"

                            onClick={() => {
                                postMetric.counter({
                                    metric: "fin_solicitud_presupuesto",
                                    value: 1,
                                    operation: "inc"
                                })
                                solicitarPresupuesto()
                            }}
                        ><FontAwesomeIcon icon="file-signature" className="mr-2" />Solicitar presupuesto</button>
                        {
                            error
                                ?
                                <div className="alert alert-danger mt-2" role="alert">
                                    {error}
                                </div>
                                :
                                null
                        }
                        {
                            confirmacion
                                ?
                                <>
                                    <div className="alert alert-success mt-2" role="alert">
                                        {confirmacion}
                                        <FontAwesomeIcon icon="spinner" className="ml-2 fa-spinner" />
                                    </div>

                                </>

                                :
                                null
                        }



                    </div>
                </LoadScript>


            </div>

        </div>

    )
}

export default Step5
