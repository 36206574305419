import React from 'react'
import Tabs from './Tabs'

const PedaniasTabs = () => {
    return (
        <div >
            <Tabs />
        </div>
    )
}

export default PedaniasTabs
