import React from 'react'
import { postMetric } from '../../../webInstrumentation/metrcisControllers'


const sendMetric = (tipo_tarjeta) =>{
    postMetric.counter({
        metric: "revisar_info_tarjetas",
        value: 1,
        labels: {"tipo_tarjeta": tipo_tarjeta},
        operation: "inc"
    })
}
const MenuTarjetas = ({settarjetaSeleccionada, tarjetaSeleccionada}) => {
   
    const handleClick = (tipo_tarjeta) =>{
        settarjetaSeleccionada(tipo_tarjeta);
        sendMetric(tipo_tarjeta)
    }
    return (
        <div className="col-sm-2 mb-5 menu-recarga-tarjeta ml-sm-5">
            <ul className="list-group clickable">

            <li className="list-group-item " id="titulo-menu-tarjetas">
                    <p>Tarjetas de transporte:</p>
                </li>
                <li className={tarjetaSeleccionada === "tarjetaVeinte" ? "list-group-item selected" : "list-group-item"}
                onClick={()=>
                    handleClick("tarjetaVeinte")}>
                    <p>Tarjeta 20 viaje</p>
                </li>
                <li className={tarjetaSeleccionada === "tarjetaPensionista" ? "list-group-item selected" : "list-group-item"}
                onClick={()=>handleClick("tarjetaPensionista")}>
                    <p>Tarjeta Pensionista</p>
                </li>
                <li className={tarjetaSeleccionada === "tarjetaSolidaria" ? "list-group-item selected" : "list-group-item"}
                onClick={()=>handleClick("tarjetaSolidaria")}>
                    <p>Tarjeta Solidaria</p>
                </li>
                <li className={tarjetaSeleccionada === "tarjetaJoven" ? "list-group-item selected" : "list-group-item"}
                onClick={()=>handleClick("tarjetaJoven")}>
                    <p>Tarjeta Joven</p>
                </li>
                <li className={tarjetaSeleccionada === "tarjetaEscolar" ? "list-group-item selected" : "list-group-item"}
                onClick={()=>handleClick("tarjetaEscolar")}>
                    <p>Tarjeta Escolar</p>
                </li>

            </ul>
            <div className="vertical-separator"></div>
        </div>
    )
}

export default MenuTarjetas
