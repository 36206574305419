import React from 'react'
import CookieBanner from 'react-cookie-banner'

const CookieBannerComponent = () => {
    return (
        <div>
        <CookieBanner
          message="Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios. Si acepta o continúa navegando, consideramos que acepta su uso. "
          onAccept={() => {}}
          cookie="user-has-accepted-cookies" 
              buttonMessage="De acuerdo"
          />
      </div>
    )
}

export default CookieBannerComponent
