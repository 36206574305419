import React, {useState} from 'react'

const BarraPedanias = ({activeitem,setactiveitem }) => {


    const activeClass="list-group-item active clickable";
    const unactiveClass=  "list-group-item clickable";


    return (
        <div id="barraPedanias">
            <ul className="list-group list-group-horizontal">
                <li className={activeitem === "p1" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p1")}>Linea - P1</li>
                <li className={activeitem === "p2" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p2")}>Linea - P2</li>
                <li className={activeitem === "p3" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p3")}>Linea - P3</li>
                <li className={activeitem === "p4" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p4")}>Linea - P4</li>
                <li className={activeitem === "p6" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p6")}>Linea - P6</li>
                <li className={activeitem === "p7" ? activeClass : unactiveClass} onClick={()=>setactiveitem("p7")}>Linea - P7</li>



            </ul>
        </div>
    )
}

export default BarraPedanias
