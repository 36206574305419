import React, {useContext, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';

const ContactDataForm = () => {



    const formularioContext = useContext(formularioDisContext)

    const { contact_data, setContact_data } = formularioContext

    const onChange = (e) => {
        setContact_data({
            ...contact_data,
            [e.target.name]: e.target.value
        })
    }


    return (
        <div>
        <p style={{fontSize: "1.6rem", borderBottom: "solid 0.5px red"}}>Datos de contacto</p>
            <div className="form-group">
                <label htmlFor="email">Correo electrónico </label>
                <input
                    value={contact_data.email}
                    name="email"
                    type="email"
                    className="form-control"
                    id="email" placeholder=""
                    onChange={(e) => onChange(e)} />
            </div>
            <div className="form-group">
                <label htmlFor="movil">Teléfono Móvil </label>
                <input
                    type="text"
                    value={contact_data.movil}

                    className="form-control"
                    name="movil"
                    id="movil"
                    placeholder=""
                    onChange={(e) => onChange(e)} />
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    name="isWhatsapp"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                    checked={contact_data.isWhatsapp}

                    onChange={(e) => {
                        setContact_data({
                            ...contact_data,
                            [e.target.name]: e.target.checked
                        })
                    }} />

                <label className="form-check-label" htmlFor="defaultCheck1">
                    Marca esta casilla si prefieres que te contactemos por WhatsApp
                                        <FontAwesomeIcon icon={['fab', 'whatsapp']} style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
                </label>
            </div>
            <label className="form-check-label" htmlFor="textarea" >Observaciones </label>
            <textarea
                className="form-control mt-2"
                rows="3"
                cols="50"
                name="observaciones"
                onChange={(e) => onChange(e)}                 
                value={contact_data.observaciones}
                id="textarea"
            ></textarea>

        </div>
    )
}

export default ContactDataForm
