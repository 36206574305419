import React, { useState, useEffect, useContext } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

import AutocompleteInput from './GoogleMaps/AutocompleteInput'

import TittleHr from '../../commons/TittleHr'

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StepsProgression from './StepsProgression';
const libraries = ['places'];

const Step2 = ({ }) => {


    const formularioContext = useContext(formularioDisContext)

    const { start_point, setEndPoint, end_point, nextStep } = formularioContext

    let initialCenter;

    if (end_point !== null) {
        initialCenter = end_point.coordinates
    }
    else if (start_point !== null) {
        initialCenter = start_point.coordinates
    }
    else {
        initialCenter = {
            lat: 36.6850064,
            lng: -6.126074399999999
        }
    }



    const [center, setcenter] = useState(initialCenter)

    const [place, setplace] = useState(null)

    const initialZoom = end_point ? 16 : 11
    const [zoom, setzoom] = useState(initialZoom)

    useEffect(() => {

        if (place !== null) {
            setEndPoint(place)
            setcenter(place.coordinates)
        }


    }, [place])

    const onDragMarker = (e) => {

        setEndPoint({
            dir_object: null,
            coordinates: {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        })

    }

    return (

        <div>
            <h4 className="ml-3 mt-3 mb-3">Paso 2: Selecciona el punto de llegada </h4>
            <TittleHr />
            <h5 className="mt-5 ml-5 mb-5">Ahora dinos donde quieres que te dejemos</h5>
            <div className="row  h-100 justify-content-md-center">

                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w"
                    libraries={libraries}
                >
                    <div className="col-sm-4">
                        <AutocompleteInput
                            place={place}
                            setplace={setplace}
                        />
                        <div className="row mt-4 ml-1">

                            <i> <FontAwesomeIcon icon="info-circle" className="mr-2" />  Puedes afinar la búsqueda pinchando y arrastrando el marcador por el mapa</i>
                        </div>

                        <StepsProgression />
                    </div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-6">
                        <GoogleMap
                            mapContainerStyle={{
                                height: "500px",
                                width: "100%"
                            }}
                            zoom={zoom}
                            center={center}
                        >
                            {
                                end_point
                                    ?
                                    <Marker
                                        onDragEnd={(e) => onDragMarker(e)}
                                        position={{
                                            lat: end_point.coordinates.lat,
                                            lng: end_point.coordinates.lng
                                        }}
                                        draggable={true}
                                    />
                                    :
                                    null
                            }
                        </GoogleMap>

                    </div>
                </LoadScript>

            </div>
        </div>
    )
}

export default Step2
