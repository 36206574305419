import React, { useContext } from 'react'
import  DateTimePicker  from 'react-datetime-picker'

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';


const DatesSelector = () => {


    const formularioContext = useContext(formularioDisContext)

    const { start_date, end_date, setStartDate, setEndDate } = formularioContext


  

    return (
        <>
            <div className="col-sm-6">
                <p style={{marginBottom: "3px"}}>Fecha de ida:</p>
                <DateTimePicker
                style={{padding: "3px", border: "thin solid #dedede;"}}
                    value={start_date}
                    disableClock={true}
                    onChange={(value) => { setStartDate(new Date(value));  }}
                />
            </div>
            <br/>
            <div className="col-sm-6">
                <p style={{marginBottom: "3px"}}>Fecha de vuelta:</p>

                <DateTimePicker
                    value={end_date}
                    disableClock={true}
                    onChange={(value) => { setEndDate(new Date(value));  }}
                />
            </div>
        </>
    )
}

export default DatesSelector
