import React, { useReducer } from 'react'

import {
    SET_END_POINT,
    SET_START_POINT,
    SET_CURRENT_STEP,
    COMPLETE_STEP,
    SET_START_DATETIME,
    SET_END_DATETIME,
    SET_ADD_INFO,
    SET_JOURNEY_INFO,
    SET_CONTACT_DATA,
    CANCEL_FORMULARIO
} from '../../types';

import formularioDisContext from './formularioDisContext';

import formularioDisReducer from './formularioDisReducer';

const FormularioDisState = props => {


    //Inicialización del state
    const initialState = {
        start_point: null,
        end_point: null,
        start_date: null,
        end_date: null,
        current_step: 1,
        //Recoge la informacion adicional
        add_info: {
            num_pasajeros: "",
            num_pasajeros_12: "",
            paradas_adicionales: "",
            observaciones: ""
        },
        //Almacena la informacion sobre la distancia y duracion del viaje
        journey_info: {
            distance: null,
            duration: null
        },
        stepProgression: [0,0,0,0,0],
        contact_data:{
            email: "",
            movil: "",
            isWhatsapp: "",
            observaciones: ""
        }
    }


    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(formularioDisReducer, initialState);

    //Funciones para modificar el state del formulario

    //Establece el objecto de localizacion del punto de partida
    const setStartPoint = (startPointLocation) => {
        dispatch({
            type: SET_START_POINT,
            payload: startPointLocation
        })
    }
    //Establece el objecto de localizacion del punto de llegada

    const setEndPoint = (endPointLocation) => {

        dispatch({
            type: SET_END_POINT,
            payload: endPointLocation
        })
    }


      //Establece el objecto de fecha de salida
      const setStartDate = (startDate) => {
        dispatch({
            type: SET_START_DATETIME,
            payload: startDate
        })
    }
    //Establece el objecto  de fecha de vuelta

    const setEndDate = (endDate) => {

        dispatch({
            type: SET_END_DATETIME,
            payload: endDate
        })
    }

    //Establece el objecto add_info 
    const setAdd_info = (info) => {

        dispatch({
            type: SET_ADD_INFO,
            payload: info
        })

    }

    //Establece el objeto journey_info
    const setJourney_info = (info) => {

        dispatch({
            type: SET_JOURNEY_INFO,
            payload: info
        })

    }

    //Establece el objeto contact_data
    const setContact_data = (data) => {

        dispatch({
            type: SET_CONTACT_DATA,
            payload: data
        })

    }

    //Aumenta en uno el contador de current step
    const nextStep = () => {

        dispatch({
            type: SET_CURRENT_STEP,
            payload: 1
        })
    }


    //Disminuye en uno el contador de current step
    const previusStep = () => {

        dispatch({
            type: SET_CURRENT_STEP,
            payload: (-1)
        })
    }

    //marca el step actual como completado en la variable stepProgression
    const completeStep = ()=>{

        dispatch({
            type: COMPLETE_STEP,
            payload: state.current_step - 1
        })

    }

    const cancelFormulario = () =>{
        dispatch(
            { type: CANCEL_FORMULARIO,
             }
        )
    }

    return (
        <formularioDisContext.Provider

            value={{
                start_point: state.start_point,
                end_point: state.end_point,
                current_step: state.current_step,
                stepProgression: state.stepProgression,
                start_date: state.start_date,
                end_date: state.end_date,
                add_info: state.add_info,
                journey_info: state.journey_info,
                contact_data: state.contact_data,

                setStartPoint,
                setEndPoint,
                nextStep,
                previusStep,
                completeStep,
                setStartDate,
                setEndDate,
                setAdd_info,
                setJourney_info,
                setContact_data,
                cancelFormulario
                
            }}

        >
            {props.children}
        </formularioDisContext.Provider>
    )
}

export default FormularioDisState;