import React from 'react'
import { Carousel } from 'react-responsive-carousel';


import bus1 from './img/bus1.jpg'

import bus2 from './img/bus2.jpg';
import bus3 from './img/bus3.jpg';
import bus4 from './img/bus4.jpg';
 import bus5 from './img/bus5.jpg';
 import bus6 from './img/bus6.jpg';
 import bus7 from './img/bus7.jpg';
 import bus8 from './img/bus8.jpg';

//import noticia1 from './img/noticia 1.jpg';


const images = [
    bus1,
    bus2,
    bus3,
 bus4,
 bus5,
 bus6,
 bus7,
 bus8


]





const PhotoCarrouselFlota = () => {


    

    return (
        <div className="col-md-5 mt-5 carousel-flota" id="photo-carousel">
            <h4 className="titulo-carousel-flota shadow">Nuestra flota</h4>
            <Carousel infiniteLoop={true} autoPlay={false}  showThumbs={false} showStatus={false}>
                {
                    images.map((image, index) => (
                        <div  key={index} >
                            <img src={image} />
                        </div>
                    ))
                }


            </Carousel>


        </div>
    )
}

export default PhotoCarrouselFlota;
