import React, { useContext, useEffect, useState } from 'react'
import TablonAnuncios from './TablonAnuncios'
import PhotoCarrousel from './PhotoCarrousel'
import PhotoCarrouselFlota from './PhotoCarrouselFlota'

import sessionContext from '../../context/session/sessionContext'
import RecargaTarjetas from '../Pedanias/RecargaTarjetas'

import axios from 'axios'

const Inicio = () => {

    const session_context = useContext(sessionContext);


    const { anuncios,setAnuncios } = session_context;

    const [anuncio, setanuncio] = useState({
        mensaje: ["No hay nuevos anuncios"],
        fechaExpiracion: "",
        fechaPublicacion: ""
    })

  
   
    const [indexanuncio, setindexanuncio] = useState(0); 

   useEffect(() => {
    const getAnuncios =  async () => {

        const anunciosFirebase =  await axios.get("https://firestore.googleapis.com/v1/projects/autos-la-valenciana/databases/(default)/documents/anunciosActivos")
        //filtrado de anuncios activos
        if(anunciosFirebase) {
            const anunciosActivosFiltrados =  anunciosFirebase.data.documents.filter(anuncio =>{ 

                let [dia, mes , year] = anuncio.fields.fechaExpiracion.stringValue.split("-")
                return new Date(year, parseInt(mes)- 1, dia) > new Date()})
           
            setAnuncios(anunciosActivosFiltrados)
            setanuncio(anunciosActivosFiltrados[0].fields)
        
        }   
        
       
        
        }
 
     getAnuncios()
    
   }, [])

    useEffect(() => {

        
       
        const  interval = setInterval(()=> {
            nextAnuncio()
        }, 5000);
            
        return () => clearInterval(interval)
        
    }, [indexanuncio])

    const nextAnuncio = () => {

        //si el anuncio actual es el último, pasamos al primero
        if (indexanuncio + 1 > anuncios.length - 1 ) {
            setanuncio(anuncios[0]);
            setindexanuncio(0);

        }
        else {
            setanuncio(anuncios[indexanuncio + 1]);
            setindexanuncio(indexanuncio + 1);
        }
        return

    }
    const previousAnuncio = () => {

        //si el anuncio actual es el último, pasamos al primero
        if (indexanuncio - 1 < 0) {
            setanuncio(anuncios[anuncios.length -1]);
            setindexanuncio(anuncios.length -1);

        }
        else {
            setanuncio(anuncios[indexanuncio - 1]);
            setindexanuncio(indexanuncio - 1);
        }
        return

    }
    return (
        <div className=" main-page">

            <PhotoCarrousel />
            <div className="row row-second-index" >
                <TablonAnuncios
                anuncio={anuncio}
                indexanuncio={indexanuncio}
                nextAnuncio={nextAnuncio}
                previousAnuncio={previousAnuncio}
                anuncios={anuncios}
                />
             
            </div>
            <div className="row row-third-index justify-content-center" >

                <div className="col-sm-10">
                    <h4 id="titulo-tarjetas-index" className="titulo-carousel-flota shadow">Información tarjetas de transporte</h4>
                    <div className="tarjetas-container-index shawdow">
                    
                        <RecargaTarjetas />
                    </div>
                </div>
            </div>





        </div>
    )
}

export default Inicio
