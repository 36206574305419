import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import sessionContext from '../../context/session/sessionContext'

import Anuncio from './Anuncio'

import styled from '@emotion/styled';

const Hr = styled.div`
        border-color: #ff2d2d;
    border-bottom: solid 0.5px #ff2d2d;
    border-right: none;
    border-left: none;
    border-top: 0px;
    width: 90%;
    height: 2px;
    margin: 0 5% 0 5%;


`
const P = styled.p`
margin-top: 1rem;
    margin-bottom: 0;
    margin-left: 2rem;
    font-size: 1.5rem;
`

//TODO: navegacion

const TablonAnuncios = ({ nextAnuncio, previousAnuncio, indexanuncio, anuncios}) => {


 

    return (
        <div className="col-md-11 ml-5 responsive" style={{minHeight:"100%"}}>


            <div className="card shadow mt-5 " id="responsive-card" style={{minHeight:"80%"}}>
                <P >Tablón de anuncios </P>
                <Hr className="mt-3" />
                <div className="card-body pb-0" >

                    {anuncios[indexanuncio]

                        ?
                        <Anuncio
                            anuncio={anuncios[indexanuncio].fields}
                        />

                        :
                        null
                    }
                    <footer className="blockquote-footer">
                        <div id="navigation-arrows">
                            <FontAwesomeIcon className="mr-2 clickable" style={{fontSize: "1.4rem"}} icon="caret-left" onClick={previousAnuncio}/>
                            {anuncios !== null
                                ?
                                <p style={{ display: "inline-block", margin: "0, 2px", fontSize: "1.4rem" }}>{indexanuncio+1}/{anuncios.length}</p>
                                :
                                null
                            }
                            <FontAwesomeIcon className="mr-2 ml-2 clickable" style={{fontSize: "1.4rem"}} icon="caret-right" onClick={nextAnuncio} />
                        </div>
                    </footer>

                </div>
            </div>
        </div>
    )
}

export default TablonAnuncios
