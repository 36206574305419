import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { postMetric } from '../../webInstrumentation/metrcisControllers'


const BotonIrGoogleMaps = ({ url, activeitem }) => {
    return (

        <a
            className={!url ? "btn btn-primary mt-3 barra-botones disabled" : "btn btn-primary mt-3 barra-botones "}
            type="button"
            onClick={
                () => {
                    postMetric.counter({
                        metric: "abrir_parada_google_maps",
                        value: 1,
                        labels: {"linea": activeitem},
                        operation: "inc"
                    })
                }
            }
            href={url}
            target="_blank"
            style={{
                marginLeft: "6%"
            }}
        ><FontAwesomeIcon className="mr-2 ml-2 clickable" icon="map-marker-alt" />Google Maps</a>

    )
}

export default BotonIrGoogleMaps
