import React, { useContext, useEffect } from 'react'
import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StepsIndicators = () => {

    const formularioContext = useContext(formularioDisContext)



    const { current_step, stepProgression } = formularioContext


    useEffect(() => {
        
    }, [stepProgression])
    //Por cada elemento en el stepProgression genero un icono en funcion de si está completado o no
    return (
        <div className="d-inline ml-3 mr-3">
            {
                stepProgression.length > 0

                    ?


                    stepProgression.map((item, index) => {

                        if (item === 0) {
                            return (<FontAwesomeIcon key={index} icon={["far","circle"]} className="ml-2" />)
                        }
                        else {
                            return (<FontAwesomeIcon key={index} icon="check-circle" className="ml-2" />)
                        }

                    })

                    : null


            }
        </div>

    )
}

export default StepsIndicators
