import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Pedanias from './Pedanias';
import RecargaTarjetas from './RecargaTarjetas';
function _Tabs() {
    return (
        <div className=" main-page h-70 pb-5">
            <div className="row message-table-row justify-content-md-center h-100" >
                <div className="col-sm-10">
                    <div className="main-container shadow-sm mt-5">

                        <div className="tabs-container">
                            <Tabs >
                                <TabList>
                                    <Tab>Rutas</Tab>
                                    <Tab>Recarga de Tarjetas</Tab>
                                </TabList>

                                <TabPanel>
                                    <Pedanias/>
                                </TabPanel>
                                <TabPanel>
                                <div style={{ backgroundColor: "white" }} className=" main-page h-70 tab-recarga-tarjetas">
                                    <RecargaTarjetas/>
                                   </div>
                        </TabPanel>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default _Tabs
