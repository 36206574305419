import React, {useState} from 'react'
import { paradas } from './data/pedanias'
const Parada = ({ index, item, setparada_coor, setparadaSeleccionada, paradaSeleccionada }) => {

    const checkHascoordinates = ()=>{
        const parada = paradas[item.nombre.toUpperCase()]
        if (parada) {
            return true
        }
        else{
            return false
        }
    }

    const [hasCoordinates, sethasCoordinates] = useState(checkHascoordinates())

    
    const setParadaCoordinates = () => {

        const parada = paradas[item.nombre.toUpperCase()]
        if (parada) {
            if (!Object.keys(parada).includes("ida")) {

                setparada_coor({
                    coordinates:{
                        lat: parada.lat,
                        lng: parada.lng
                    }
                })
                // return `https://maps.google.com/?q=${parada.lat},${parada.lng}`
                return 0

            }
            else {

                setparada_coor({
                    coordinates:{
                        lat: parada.ida.lat,
                        lng: parada.ida.lng
                    }
                })
                // return `https://maps.google.com/?q=${parada.ida.lat},${parada.ida.lng}`
                return 0
            }
        }
        else {
            return ""
        }

    }

    const handleClick = () =>{

        sethasCoordinates(checkHascoordinates())
        if(!hasCoordinates){
            setparada_coor(null)
        }
        else{
           setParadaCoordinates()
        setparadaSeleccionada(item.nombre) 
        }
        

    }



    return (
        <div>
        
            

            <li key={index} onClick={handleClick} 
            className={paradaSeleccionada === item.nombre ? "list-group-item li-pedanias-selected clickable" : "list-group-item li-pedanias clickable"}>
            {index + 1}- {item.nombre}</li>

        

        </div>
    )
}

export default Parada
