import React  from 'react'
import Collapsible from 'react-collapsible'
import ContentInfoTarjeta from './ContentInfoTarjeta'
function CollapsableLi({title, id}) {

    

    return (
        <li className="list-group-item collapsible">
            <Collapsible trigger={title} lazyRender={true} transitionTime={200}>
                <ContentInfoTarjeta id={id}/>
            </Collapsible>
        </li>
    )
}

export default CollapsableLi
