
import {
    SET_START_POINT,
    SET_END_POINT,
    SET_CURRENT_STEP,
    COMPLETE_STEP,
    SET_START_DATETIME,
    SET_END_DATETIME,
    SET_ADD_INFO,
    SET_JOURNEY_INFO,
    SET_CONTACT_DATA,
    CANCEL_FORMULARIO
} from '../../types'

const initialState = {
    start_point: null,
    end_point: null,
    start_date: null,
    end_date: null,
    current_step: 1,
    //Recoge la informacion adicional
    add_info: {
        num_pasajeros: "",
        num_pasajeros_12: "",
        paradas_adicionales: "",
        observaciones: ""
    },
    //Almacena la informacion sobre la distancia y duracion del viaje
    journey_info: {
        distance: null,
        duration: null
    },
    stepProgression: [0,0,0,0,0],
    contact_data:{
        email: "",
        movil: "",
        isWhatsapp: "",
        observaciones: ""
    }
}


export default (state, action) => {


    switch (action.type) {
        case SET_START_POINT:
            return {
                ...state,
                start_point: action.payload
            }
        case SET_END_POINT:
            return {
                ...state,
                end_point: action.payload
            }
        case SET_START_DATETIME:
            return {
                ...state,
                start_date: action.payload
            }
        case SET_END_DATETIME:
            return {
                ...state,
                end_date: action.payload
            }
        case SET_ADD_INFO:
            return {
                ...state,
                add_info: action.payload
            }
        case SET_JOURNEY_INFO:
            return {
                ...state,
                journey_info: action.payload
            }
        case SET_CONTACT_DATA:
            return {
                ...state,
                contact_data: action.payload
            }
        case SET_CURRENT_STEP:
            return {
                ...state,
                current_step: state.current_step + action.payload
            }
        case CANCEL_FORMULARIO:
            return initialState
            
        case COMPLETE_STEP:
            const newStateProgression = []
            state.stepProgression.map((item, index) => {
                if (index === action.payload || item === 1) {
                    newStateProgression.push(1)
                }
                else {
                    newStateProgression.push(0)
                }

                return
            })

            return {
                ...state,
                stepProgression: newStateProgression
            }
        default:
            return state;
    }
}