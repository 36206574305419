import {
    SET_ACTIVE_PAGE, SET_ANUNCIOS
} from '../../types'




export default (state, action) => {


    switch (action.type) {
        case SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload
            }
        case SET_ANUNCIOS:
            return {
                ...state,
                anuncios: action.payload
            }
        default:
            return state;
    }
}