import React from 'react'


//import de las imagenes



import BotonDescargarHorarios from './BotonDescargarHorarios';
import BotonIrGoogleMaps from './BotonIrGoogleMaps';

const Opciones = ({ activeitem, parada_coor }) => {


    const url_pdf_cloud_store = {
        p1: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp1.pdf?alt=media&token=6f4c121a-c8b6-4413-8572-1af69c781655",
        p2: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp2.pdf?alt=media&token=befe1ac7-88c4-4fd1-bf54-5db7147f379f",
        p3: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp3.pdf?alt=media&token=6f4c121a-c8b6-4413-8572-1af69c781655",
        p4: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp4.pdf?alt=media&token=6f4c121a-c8b6-4413-8572-1af69c781655",
        p6: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp6.pdf?alt=media&token=6f4c121a-c8b6-4413-8572-1af69c781655",
        p7: "https://firebasestorage.googleapis.com/v0/b/autos-la-valenciana.appspot.com/o/pdf%2Fp7.pdf?alt=media&token=b52fa276-2a67-475a-ba80-50d0271a40a0",
    }

    
    const createGoogleMapsURl = () =>{

        if(parada_coor)
        return `https://maps.google.com/?q=${parada_coor.coordinates.lat},${parada_coor.coordinates.lng}`

    }


    return (
        <div className=" opciones ">
            <BotonDescargarHorarios 
               activeitem={activeitem}
                pdf={url_pdf_cloud_store[activeitem]}
            />
            <BotonIrGoogleMaps
            activeitem={activeitem}
                url={createGoogleMapsURl()}
               
            />
           

        </div>
    )
}

export default Opciones
