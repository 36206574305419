const pedanias = {

    p1: {
        paradas:
            [{
                nombre: "Angustias",
                localizacion: ""
            },
            {
                nombre: "Estación",
                localizacion: ""
            },
            {
                nombre: "Delicias",
                localizacion: ""
            },
            {
                nombre: "Cementerio",
                localizacion: ""
            },
            {
                nombre: "Estella",
                localizacion: ""
            },
            {
                nombre: "Venta Gabriel",
                localizacion: ""
            },
            {
                nombre: "Depuradora",
                localizacion: ""
            },
            {
                nombre: "Butano",
                localizacion: ""
            },
            {
                nombre: "La Guareña",
                localizacion: ""
            },
            {
                nombre: "Magallanes",
                localizacion: ""
            },
            {
                nombre: "San Isidro",
                localizacion: ""
            },
            {
                nombre: "El Torno",
                localizacion: ""
            },
            {
                nombre: "La Barca",
                localizacion: ""
            },
            {
                nombre: "Majarromaque",
                localizacion: ""
            },
            {
                nombre: "Torrecera Alta",
                localizacion: ""
            },
            {
                nombre: "Torrecera Baja",
                localizacion: ""
            },
            ]
    },
    p2: {
        paradas:
            [{
                nombre: "Minotauro",
                localizacion: ""
            },
            {
                nombre: "Plaza del Caballo",
                localizacion: ""
            },
            {
                nombre: "Plaza las Marinas",
                localizacion: ""
            },
            {
                nombre: "Hospital",
                localizacion: ""
            },
            {
                nombre: "Mesas de Asta",
                localizacion: ""
            }
            ]
    },
    p3: {
        paradas:
            [{
                nombre: "Angustias",
                localizacion: ""
            },
            {
                nombre: "Madre de Dios",
                localizacion: ""
            },
            {
                nombre: "Cuatro Caminos",
                localizacion: ""
            },
            {
                nombre: "Polila",
                localizacion: ""
            },
            {
                nombre: "Las Tablas",
                localizacion: ""
            },
            {
                nombre: "Añina",
                localizacion: ""
            }
            ]
    },
    p4: {
        paradas:
            [{
                nombre: "Angustias",
                localizacion: ""
            },
            {
                nombre: "Minotauro",
                localizacion: ""
            },
            {
                nombre: "La Granja",
                localizacion: ""
            },
            {
                nombre: "Circuito",
                localizacion: ""
            },
            {
                nombre: "Torremelgarejo",
                localizacion: ""
            },
            {
                nombre: "La Inmaculada",
                localizacion: ""
            },
            {
                nombre: "Gibalbin",
                localizacion: ""
            }
            ]
    },
    p6: {
        paradas:
            [{
                nombre: "Angustias",
                localizacion: ""
            },
            {
                nombre: "Minotauro",
                localizacion: ""
            },
            {
                nombre: "Centro de Salud",
                localizacion: ""
            },
            {
                nombre: "Pinosolete",
                localizacion: ""
            },
            {
                nombre: "Cartuja",
                localizacion: ""
            },
            {
                nombre: "Albarizones",
                localizacion: ""
            },
            {
                nombre: "La Corta",
                localizacion: ""
            }
                ,
            {
                nombre: "Lomopardo",
                localizacion: ""
            }
                ,
            {
                nombre: "La Ina",
                localizacion: ""
            }
                ,
            {
                nombre: "Rajamancera",
                localizacion: ""
            }
                ,
            {
                nombre: "El Palomar",
                localizacion: ""
            }
            ]
    },
    p7: {
        paradas:
            [{
                nombre: "Angustias",
                localizacion: ""
            },
            {
                nombre: "Minotauro",
                localizacion: ""
            },
            {
                nombre: "Centro de Salud",
                localizacion: ""
            },
            {
                nombre: "Pinosolete",
                localizacion: ""
            },
            {
                nombre: "Cartuja",
                localizacion: ""
            },
            {
                nombre: "Albarizones",
                localizacion: ""
            },
            {
                nombre: "La Corta",
                localizacion: ""
            }
                ,
            {
                nombre: "Lomopardo",
                localizacion: ""
            }
                ,
            {
                nombre: "Las Pachecas",
                localizacion: ""
            }

            ]
    }


}

export const paradas =
{
    ANGUSTIAS: { lng: -6.133794444444445, lat: 36.680566666666664 },
    MINOTAURO: { lng: -6.127775, lat: 36.680817 },
   'MADRE DE DIOS': { lng: -6.127775, lat: 36.680817 },
    ESTACIÓN: {
      ida: { lng: -6.128288888888888, lat: 36.680675 },
      vuelta: { lng: -6.127775, lat: 36.680816666666665 }
    },
    DELICIAS: {
      ida: { lng: -6.118802777777777, lat: 36.68204166666666 },
      vuelta: { lng: -6.118802777777777, lat: 36.68204166666666 }
    },
    CEMENTERIO: {
      ida: { lng: -6.096216666666666, lat: 36.68555833333333 },
      vuelta: { lng: -6.0962499999999995, lat: 36.68592222222222 }
    },
    ESTELLA: {
      ida: { lng: -6.080033333333333, lat: 36.686166666666665 },
      vuelta: { lng: -6.0796777777777775, lat: 36.68631944444444 }
    },
    'LA CANASTA': {
      ida: { lng: -6.0444555555555555, lat: 36.677905555555554 },
      vuelta: { lng: -6.04455, lat: 36.67808888888889 }
    },
    BRISTOL: {
      ida: { lng: -6.039563888888889, lat: 36.67714722222222 },
      vuelta: { lng: -6.039902777777778, lat: 36.67728611111111 }
    },
    CUARTILLOS: { ida: { lng: -6.029155555555556, lat: 36.678913888888886 } },
    'CUARTILLOS ': { vuelta: { lng: -6.028180555555555, lat: 36.678869444444445 } },
    'VENTA GABRIEL': {
      lng: -6.021022222222222,
      lat: 36.6768,
      ida: { lng: -6.021041666666666, lat: 36.67665833333333 }
    },
    DEPURADORA: {
      vuelta: { lng: -6.013836111111111, lat: 36.679741666666665 },
      ida: { lng: -6.013847222222222, lat: 36.67958333333333 }
    },
    'LA GUAREÑA': {
      ida: { lng: -5.982066666666666, lat: 36.66163611111111 },
      vuelta: { lng: -5.980933333333334, lat: 36.66181944444444 }
    },
    BUTANO: {
      ida: { lng: -5.959508333333334, lat: 36.65134166666667 },
      vuelta: { lng: -5.959508333333334, lat: 36.65134166666667 }
    },
    MAGALLANES: {
      ida: { lng: -5.954258333333334, lat: 36.65133888888889 },
      vuelta: { lng: -5.954258333333334, lat: 36.65133888888889 }
    },
    'LA BARCA': {
      ida: { lng: -5.933786111111111, lat: 36.64918611111111 },
      vuelta: { lng: -5.932966666666667, lat: 36.64926666666667 }
    },
    MAJARROMAQUE: {
      vuelta: { lng: -5.906661111111111, lat: 36.671527777777776 },
      ida: { lng: -5.908, lat: 36.66981388888889 }
    },
    'TORRECERA ALTA': {
      ida: { lng: -5.943555555555556, lat: 36.60971666666667 },
      vuelta: { lng: -5.943555555555556, lat: 36.60971666666667 }
    },
    'TORRECERA BAJA': { lng: -5.9547944444444445, lat: 36.59876388888889 },
    'TORRECERA BAJA II': { lng: -5.948877777777778, lat: 36.60048611111111 },
    'TORRECERA BAJA III': { lng: -5.946383333333333, lat: 36.60305 },
    'EL TORNO': { lng: -5.969280555555556, lat: 36.61910833333334 },
    'EL TORNO II': { lng: -5.973563888888889, lat: 36.62201944444445 },
    'SAN ISIDRO II': { lng: -5.9868805555555555, lat: 36.64388888888889 },
    'SAN ISIDRO': {
      ida: { lng: -5.990716666666667, lat: 36.64348888888889 },
      vuelta: { lng: -5.990713888888889, lat: 36.64360277777778 }
    },
    'CUATRO CAMINOS': { lng: -6.1449694444444445, lat: 36.677702777777775 },
    'POLILA': { lng: -6.205697222222223, lat: 36.69200833333333 },
    'LAS TABLAS': { lng: -6.217347222222222, lat: 36.706450000000004 },
    'AÑINA': { lng: -6.212613888888889, lat: 36.71581944444445 },
    'SAN JUAN BOSCO': { lng: -6.147658333333334, lat: 36.69106111111111 },
    'CENTRO DE SALUD': { lng: -6.112138888888889, lat: 36.673880555555556 },
    'PINOSOLETE II': {
      vuelta: { lng: -6.118077777777778, lat: 36.663716666666666 },
      ida: { lng: -6.118286111111111, lat: 36.663463888888884 }
    },
    'PINOSOLETE': {
      ida: { lng: -6.120755555555555, lat: 36.666711111111105 },
      vuelta: { lng: -6.1206555555555555, lat: 36.66674444444444 }
    },
    CARTUJA: {
      vuelta: { lng: -6.115947222222222, lat: 36.665905555555554 },
      ida: { lng: -6.115288888888888, lat: 36.66623055555556 }
    },
    'EL TORO': { ida: { lng: -6.109244444444444, lat: 36.662869444444446 } },
    ALBARIZONES: {
      ida: { lng: -6.1008, lat: 36.658680555555556 },
      vuelta: { lng: -6.101216666666667, lat: 36.658963888888884 }
    },
    'LA CORTA': { lng: -6.105238888888889, lat: 36.65416388888889 },
    'LOMOPARDO II': { lng: -6.082633333333333, lat: 36.654111111111106 },
    'LOMOPARDO': { lng: -6.08015, lat: 36.65519444444444 },
    'LAS CARRETAS': { vuelta: { lng: -6.056694444444444, lat: 36.64454722222222 } },
    'VENTA ROCIO': { vuelta: { lng: -6.035605555555556, lat: 36.637875 } },
    'LA INA': {
      vuelta: { lng: -6.029836111111111, lat: 36.636247222222224 },
      ida: { lng: -6.0300416666666665, lat: 36.63616666666667 }
    },
    RAJAMANCERA: { lng: -6.007755555555556, lat: 36.62928055555555 },
    'EL PALOMAR': { lng: -6.003358333333333, lat: 36.62825 },
    'LAS PACHECAS': { lng: -6.076033333333333, lat: 36.633180555555555 },
    'LAS PACHECAS II': { lng: -6.074883333333333, lat: 36.63102777777778 },
    'LAS PACHECAS III': { lng: -6.073652777777777, lat: 36.629130555555555 },
    'LAS PACHECAS IV': { lng: -6.072469444444444, lat: 36.628369444444445 },
    'LAS PACHECAS V': { lng: -6.0710444444444445, lat: 36.626358333333336 },
    'LOS LAGOS': { lng: -6.075411111111111, lat: 36.63603333333333 },
    'PLAZA DEL CABALLO': {lng: -6.129325, lat: 36.69363611111111},
    'PLAZA LAS MARINAS': {lng: -6.1368, lat: 36.69784166666666},
    'HOSPITAL': {'lng': -6.146377777777778, 'lat': 36.70013055555556},
    'MESAS DE ASTA': {lng: -6.17215, lat: 36.78411944444444}
  }


export default pedanias;
