import React, { useState } from 'react'

const SelectorPedanias = ({ activeitem, setactiveitem }) => {
    return (
        <div id="selectorPedanias">
            
                <select className="custom-select w-75 ml-5" onChange={e => setactiveitem(e.target.value)}>
                    <option value="p1" defaultValue="p1" >Linea P1</option>
                    <option value="p2" >Linea P2</option>
                    <option value="p3" >Linea P3</option>
                    <option value="p4" >Linea P4</option>
                    <option value="p6" >Linea P6</option>
                    <option value="p7" >Linea P7</option>


                   
                </select>
            
        </div>
    )
}

export default SelectorPedanias
