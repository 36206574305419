const tarjetas = {
    tarjetaVeinte : {
        nombre: "Tarjeta 20 viajes",
        descripcion: "Tarjeta recargable sin identificación ni caucidad.",
        beneficiarios: null,
        costes: "Importe de fianza 5 €.",
        
        precioRecarga: "14 € para 20 viajes (0,70 € por billete).",
        documentacion: []

    },
    tarjetaPensionista: {
        nombre: "Tarjeta Pensionistas",

        descripcion: "Tarjeta personalizada para pensionistas.",
        beneficiarios: "Jubilados y pensionistas con minusvalía igual o superior al 33%.",
        costes: "5 € de tasa alta ",
        precioRecarga: "1.10 € para 10 viajes (0.11 € por billete).",
        documentacion: [
            "Acreditación de estado pensionista.",
            "Documento que acredite un nivel de minusvalía superior al 33%. ",
            "Fotocopia del DNI.",
            "Fotografía de carné."
        ]

    },
    tarjetaSolidaria: {
        nombre: "Tarjeta Solidaria",

        descripcion: "Tarjeta personalizada para personas en condición de desempleo.",
        beneficiarios: "Personas desempleadas durante más de 10 meses, que puedan acreditar los últimos 12 meses de vida laboral. Será necesario renovar la tarjeta actualizando la documentación laboral cada 3 meses.",
        costes: "5 € de tasa alta.",

        precioRecarga: "1.10 € para 10 viajes (0.11 € por billete).",
        documentacion: [
            "Fotocopia del informe de vida laboral actualizado al último més.",
            "Fotocopia del carné del paro en vigencia.",
            "Fotocopia del DNI.",
            "Fotografía de carné."
        ]

    },
    tarjetaJoven: {
        nombre: "Tarjeta Joven",
        descripcion: "Abono transporte mensual para jóvenes.",
        beneficiarios: "Jóvenes menores de 30 años.",
        costes: null,
        precioRecarga: "15€ con viajes ilimitados durante los siguientes 30 días.",
        documentacion: [
           
            "Fotocopia del DNI.",
            "Fotografía de carné."
        ]

    },
    tarjetaEscolar: {
        nombre: "Tarjeta Escolar",
        descripcion: "Abono transporte para personas escolarizadas, desde los 3 años hasta 4º de la ESO.",
        beneficiarios: "Niños de 3 años hasta jovenes de 18 años.",
        costes: null,
        precioRecarga: "2€ con viajes ilimitados desde el día de activación hasta el 31 de agosto del mismo año.",
        documentacion: [
           
            "Fotocopia del certificado de matriculación.",
            "Fotocopia del DNI.",
            "Fotocopia del libro de familia."
        ]

    }
}

export default tarjetas;