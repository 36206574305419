import React, { useState, useEffect } from 'react'
import pedanias from './data/pedanias'
import Parada from './Parada'




const Ruta = ({ activeitem, setparada_coor }) => {

    const [rutaPedanias, setRutaPedanias] = useState(pedanias[Object.keys(pedanias)[0]].paradas)

    const [paradaSeleccionada, setparadaSeleccionada] = useState(null)


    useEffect(() => {

        setRutaPedanias(pedanias[activeitem].paradas)


    }, [activeitem]);

    return (
        <div className="col-sm-3  col-ruta container-ruta shadow-sm ml-5 mt-5 mb-5" style={{maxHeight: "600px", overflowY: "scroll" , border: "solid 0.5px gainsboro"}}>

            <h4 className="mt-3 ml-3">Itinerario</h4>
            <i className="ml-3 mt-5">Seleccione una parada para acceder a su localización</i>
            <ul className="list-group m-3" style={{borderRadius: "0px"}}>
                {rutaPedanias.map((item, index) => (
                    <Parada 
                    key={index} 
                    item={item} 
                    index={index} 
                    setparada_coor={setparada_coor}
                    setparadaSeleccionada = {setparadaSeleccionada}
                    paradaSeleccionada = {paradaSeleccionada}
                    />
                ))


                }
            </ul>

        </div>
    )
}

export default Ruta
