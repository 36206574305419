import React, {useContext} from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';


const CancelButton = ({setdesplegarFormulario}) => {

    const formularioContext = useContext(formularioDisContext)

    const { cancelFormulario } = formularioContext

    return (
        <button style={{"zIndex" : "99999", "width": "100px"}} className="btn btn-primary mt-3 mb-5 ml-5"

        onClick={()=> {
            setdesplegarFormulario(false)
            cancelFormulario()
            //console.log("cancel")
            }}
       >
        Cancelar</button>

    )
}

export default CancelButton
