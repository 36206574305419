

//SESSION TYPES
export const SET_ACTIVE_PAGE = "ACTIVE_PSET_ACTIVE_PAGEAGE"
export const SET_ANUNCIOS = "SET_ANUNCIOS"

export const SET_START_POINT = "SET_START_POINT"
export const SET_END_POINT = "SET_END_POINT"
export const SET_START_DATETIME = "SET_START_DATETIME"
export const SET_END_DATETIME = "SET_END_DATETIME"
export const SET_ADD_INFO = "SET_ADD_INFO"
export const SET_CONTACT_DATA = "SET_CONTACT_DATA"

export const CANCEL_FORMULARIO = "CANCEL_FORMULARIO"

export const SET_JOURNEY_INFO = "SET_JOURNEY_INFO"

export const SET_CURRENT_STEP = "SET_CURRENT_STEP"

export const COMPLETE_STEP = "COMPLETE_STEP"

