import React, {useContext} from 'react'
import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';

const datetoString = (date) =>{

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hour = date.getHours()
    const minutes = date.getMinutes()

    const str_day = day < 10 ? "0" + day : day
    const str_month = month < 10 ? "0" + month : month
    const str_hour = hour < 10 ? "0" + hour : hour
    const str_minutes = minutes < 10 ? "0" + minutes : minutes

    return `${str_day}-${str_month}-${year} ${str_hour}:${str_minutes}`
}

const JourneySumup = () => {
    const formularioContext = useContext(formularioDisContext)

    const { start_point, end_point, start_date, end_date, add_info, journey_info } = formularioContext

    return (
        <div>
        <p style={{fontSize: "1.6rem", borderBottom: "solid 0.5px red", "marginTop": "1rem"}}>Resumen del viaje:</p>
        <p><strong>Punto de salida:</strong> { start_point.dir_object!== null ? start_point.dir_object.formatted_address : `${start_point.coordinates.lat.toFixed(4)}, ${start_point.coordinates.lng.toFixed(4)}`} ({datetoString(start_date)})</p>
        <p><strong>Punto de llegada:</strong> { end_point.dir_object!== null ? end_point.dir_object.formatted_address : `${end_point.coordinates.lat.toFixed(4)}, ${end_point.coordinates.lng.toFixed(4)}`} ({datetoString(end_date)})</p>
        <p><strong>Número total de pasajeros:</strong> {add_info.num_pasajeros} ({add_info.num_pasajeros} menores de 12 años)</p>
      
        <p><strong>Distancia total (km):</strong> {journey_info !== null ? journey_info.distance : ""}</p>
        <p><strong>Duración aproximada del trayecto:</strong> {journey_info !== null ? journey_info.duration : ""}</p>
            
        </div>
    )
}

export default JourneySumup
