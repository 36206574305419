import React from 'react'
import CollapsibleLi from './CollapsableLi';

function MenuTarjetasCollapsable() {
    return (
        <div className="col-sm-3 mb-5 menu-recarga-tarjetas">
            <h4>Información tarjetas</h4>
            <ul className="list-group">

                <CollapsibleLi
                    title={"Tarjeta 20 viajes"}
                    id={"tarjetaVeinte"}
                    key={1}
                />
                <CollapsibleLi
                    title={"Tarjeta Pensionista"}
                    id={"tarjetaPensionista"}
                    key={2}
                />
                <CollapsibleLi
                    title={"Tarjeta Solidaria"}
                    id={"tarjetaSolidaria"}
                    key={3}
                />
                <CollapsibleLi
                    title={"Tarjeta Joven"}
                    id={"tarjetaJoven"}
                    key={4}
                />
                <CollapsibleLi
                    title={"Tarjeta Escolar"}
                    id={"tarjetaEscolar"}
                    key={5}
                />


            </ul>
        </div>
    )
}

export default MenuTarjetasCollapsable
