import React, { useState, useEffect } from 'react'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'


import BarraPedanias from './BarraPedanias'
import SelectorPedanias from './SelectorPedanias';
import Ruta from './Ruta';
import Opciones from './Opciones';
const libraries = ["places"];

const Pedanias = () => {

    const [activeitem, setactiveitem] = useState("p1");

    const initialCenter = {
        lat: 36.6850064,
        lng: -6.126074399999999
    }

     //state para alamcenar las coordenadas de la parada seleccionada
     const [parada_coor, setparada_coor] = useState(null)
    useEffect(() => {

        if (parada_coor !== null) {
            
            setcenter(parada_coor.coordinates)
            setzoom(16)

        }
        

    }, [parada_coor])


    const [center, setcenter] = useState(initialCenter)
   

    const initialZoom = parada_coor ? 16 : 11
    const [zoom, setzoom] = useState(initialZoom)

    return (
        <div style={{ backgroundColor: "white" }} className=" main-page h-70">
            <div className="row message-table-row justify-content-md-center h-100" >
                <div className=" mt-4">
                    <BarraPedanias
                        activeitem={activeitem}
                        setactiveitem={setactiveitem}

                    />
                    <SelectorPedanias
                        activeitem={activeitem}
                        setactiveitem={setactiveitem}

                    />
                </div>
            </div>
            <div className="row message-table-row  h-100" >
                <Ruta
                    activeitem={activeitem}
                    setparada_coor = {setparada_coor}
                />
                {/*
                <ImagenRuta
                                    activeitem={activeitem}
                                />
                */}
                <div className="col-sm-6 div-map p-0 shadow-sm ml-5 mt-5 mb-5">

                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey="AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w"
                        libraries={libraries}
                    >
                        <GoogleMap 
                            mapContainerStyle={{
                                width: "100%",
                                height: "100%"
                            }}
                            zoom={zoom}
                            center={center}
                        >
                        {
                                parada_coor
                                    ?
                                    <Marker
                                        
                                        position={{
                                            lat: parada_coor.coordinates.lat,
                                            lng: parada_coor.coordinates.lng
                                        }}
                                      
                                    />
                                    :
                                    null
                            }

                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className="col-sm-2">
                    <Opciones
                        activeitem={ activeitem }
                        parada_coor={parada_coor}
                    />
                </div>

            </div>
            <div className="row ml-5 mt-4 mt-sm-0">
                *Los horario de paso por las paradas son aproximados y estan sujetos a circunstancias del tráfico
            </div>
        </div>
    )
}

export default Pedanias
