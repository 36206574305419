import React, { useContext, useState, useEffect } from 'react'
import TittleHr from '../../commons/TittleHr'
import styled from '@emotion/styled'
import formularioDisContext from '../../../context/formularioDiscrecionales/formularioDisContext';
import StepsProgression from './StepsProgression';


const Label = styled.label`

    border-bottom: solid 0.5px red;


`

//Formulario con preguntas adicionales para el 4 paso del formulario
const Step4 = () => {

    const formularioContext = useContext(formularioDisContext)

    const { add_info, setAdd_info } = formularioContext;

    const [formulario, setformulario] = useState(add_info != null ? add_info : {
        num_pasajeros: "",
        num_pasajeros_12: "",
        paradas_adicionales: "",
        observaciones: ""
    })

    useEffect(() => {
        setAdd_info(formulario)
    }, [formulario])

    const onChange = (e) => {
        setformulario({
            ...formulario,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <h4 className="ml-3 mt-3 mb-3">Paso 4: Información adicional</h4>
            <TittleHr />
            <h5 className="mt-5 ml-5 mb-5">Solo unas preguntas más...</h5>
            <div>
                <div className="row  h-100 justify-content-md-center">

                    <div className="col-sm-6">
                        <div className="form-group mb-5 ">
                            <Label htmlFor="num_pasajeros">Número de pasajeros: </Label>
                            <input type="number" name="num_pasajeros" className="form-control" value={add_info.num_pasajeros}  onChange={(e) => onChange(e)} id="num_pasajeros" />
                        </div>


                        <div className="form-group">
                            <Label htmlFor="num_pasajeros_12">Número de pasajeros menores de 12 años: </Label>
                            <input type="number" name="num_pasajeros_12" className="form-control" value={add_info.num_pasajeros_12} onChange={(e) => onChange(e)} id="num_pasajeros" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group ">
                            <Label htmlFor="paradas_adicionales">Incluye paradas intermedias si las hubiera:</Label>
                            <textarea className="form-control" name="paradas_adicionales" value={add_info.paradas_adicionales} onChange={(e) => onChange(e)} id="paradas_adicionales" rows="2"></textarea>
                        </div>
                        <div className="form-group mb-5">
                            <Label htmlFor="paradas_adicionales">Comentarios u observaciones:</Label>
                            <textarea className="form-control" name="observaciones" value={add_info.observaciones} onChange={(e) => onChange(e)} id="observaciones" rows="2"></textarea>
                        </div>
                    </div>



                </div>

                <div className="row  h-100 justify-content-sm-center mb-5">
                    <StepsProgression />
                </div>
            </div>
        </div >
    )
}

export default Step4

