import React, { useState, useEffect } from 'react'
import tarjetas from '../data/tarjetas'


const ContentInfoTarjetasFullWidth = ({id}) => {

    const [content, setcontent] = useState(tarjetas[id])

    const { nombre, descripcion, beneficiarios, costes, precioRecarga, documentacion} = content
    
    useEffect(() => {
        setcontent(tarjetas[id])
    }, [id])

    return (
        <div className="col-sm-11">
        <h5 >{nombre}</h5>
        <hr/>
        <p className="mb-2">{descripcion}</p>
        <p className="seccion-tarjeta full">Beneficiarios:</p>
        <p className="info-tarjeta">{beneficiarios ? beneficiarios : "Cualquier viajero sin restricciones de edad ni condición."}</p>
        <p className="seccion-tarjeta full">Tasa de alta:</p>
        <p className="info-tarjeta">{costes ? costes : "Sin tasas de alta."}</p>
        <p className="seccion-tarjeta full">Precio de la recarga:</p>
        <p className="info-tarjeta">{precioRecarga}</p>
        <p className="seccion-tarjeta full">Documentación a presentar:</p>
        
            <ul>
            {
                documentacion && documentacion.length > 0

                ? 
                documentacion.map( (doc, index)=>(
                    <li key={index}>{doc}</li>
                )

                )
                :
                "No es necesario presentar ningun tipo de documentación."
            }
            </ul>
        

    </div>
    )
}

export default ContentInfoTarjetasFullWidth
