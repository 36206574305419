import React, { useContext } from 'react'

import Step1 from './formulario/Step1'
import Step2 from './formulario/Step2'
import Step3 from './formulario/Step3'
import Step4 from './formulario/Step4'
import Step5 from './formulario/Step5'



import formularioDisContext from '../../context/formularioDiscrecionales/formularioDisContext';
import CancelButton from './formulario/CancelButton'


const FormularioPresupuesto = ({setdesplegarFormulario}) => {

    const formularioContext = useContext(formularioDisContext)

    const { current_step } = formularioContext


    return (
        <div className="card shadow-sm h-100 p-2">
           

            { current_step === 1 ? <Step1/>  : null }
            { current_step === 2 ? <Step2/>  : null }
            { current_step === 3 ? <Step3/>  : null }
            { current_step === 4 ? <Step4/>  : null }
            { current_step === 5 ? <Step5/>  : null }

            <CancelButton setdesplegarFormulario={setdesplegarFormulario}/>

        </div>
    )
}

export default FormularioPresupuesto
