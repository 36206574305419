import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import puntosRecarga from '../data/puntosRecarga'

import { postMetric } from '../../../webInstrumentation/metrcisControllers'
const libraries = ["places"];

const sendMetric = (punto_recarga) =>{
    postMetric.counter({
        metric: "consultar_loc_puntos_recarga",
        value: 1,
        labels: {"punto_recarga": punto_recarga},
        operation: "inc"
    })
}

const PuntosRecarga = () => {

    const [selected, setselected] = useState(null)
    const initialCenter = {
        lat: 36.6850064,
        lng: -6.126074399999999
    }


    useEffect(() => {
        if (selected != null) {
            setpunto_coor(puntosRecarga[selected])
            setzoom(16)
            setcenter(puntosRecarga[selected])
        }

    }, [selected])

    //state para alamcenar las coordenadas del punto de recarga seleccionada
    const [punto_coor, setpunto_coor] = useState(null)
    const [center, setcenter] = useState(initialCenter)


    const initialZoom = 11
    const [zoom, setzoom] = useState(initialZoom)

    const handleClick = (punto_recarga) =>{
        setselected(punto_recarga);
        sendMetric(punto_recarga)
    }
    return (
        <div className="col-sm-8 ml-sm-5">
            <h4 className="mb-sm-5 ">Localización de los puntos de recarga de tarjetas:</h4>
            <div className="row">
                <div className="col-sm-3">
                    <ul className="list-group">
                        <li className={selected == "jerez" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            onClick={() => {
                                handleClick("jerez")
                            }}
                        >
                            <p >Jerez de la Frontera <i style={{fontSize: "0.8rem"}}>(Viajes Selma)</i></p>
                        </li>
                        <li className={selected == "barca" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            onClick={() => {
                                handleClick("barca")
                            }}
                        >
                            <p>La Barca <i style={{fontSize: "0.8rem"}}>(Ferreteria el Cuartillo)</i></p>
                        </li>
                        <li className={selected == "isidro" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            onClick={() => {
                                handleClick("isidro")
                            }}>
                            <p>San Isidro <i style={{fontSize: "0.8rem"}}>(Ayuntamiento)</i></p>
                        </li>
                        <li className={selected == "torno" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            onClick={() => {
                                handleClick("torno")
                            }}>
                            <p>El Torno <i style={{fontSize: "0.8rem"}}>(Ayuntamiento)</i></p>
                        </li>
                        <li className={selected == "gibalbin" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            onClick={() => {
                                handleClick("gibalbin")
                            }}>
                            <p>Gibalbin <i style={{fontSize: "0.8rem"}}>(Ayuntamiento)</i></p>
                        </li>
                        <li className={selected == "torrecera" ? "list-group-item punto-recarga-selected" : "list-group-item punto-recarga"}
                            style={{ borderBottom: "none" }}
                            onClick={() => {
                                handleClick("torrecera")
                            }}>
                            <p>Torrecera <i style={{fontSize: "0.8rem"}}>(Ayuntamiento)</i></p>
                        </li>

                    </ul>
                </div>
                <div className="col-sm-9 map-container">
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey="AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w"
                        libraries={libraries}
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                width: "100%",
                                height: "100%"
                            }}
                            zoom={zoom}
                            center={center}
                        >
                            {
                                punto_coor
                                    ?
                                    <Marker

                                        position={{
                                            lat: punto_coor.lat,
                                            lng: punto_coor.lng
                                        }}

                                    />
                                    :
                                    null
                            }

                        </GoogleMap>
                    </LoadScript>
                </div>

            </div>
        </div>
    )
}

export default PuntosRecarga
