import React, { useContext } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";



import aviso from './img/aviso-covid.jpg'
import discrecional from './img/anuncio-discrecional.jpg'
//import noticia1 from './img/noticia 1.jpg';
import sessionContext from '../../context/session/sessionContext';


const images = [
    // { name: "aviso", photo: aviso },
     { name: "discrecional", photo: discrecional },
//     {name:"especial_navidad", photo: "https://documents.enerlence.com/f/7a1c8d567c054b6b9cb3/?dl=1"},
//    {name: "especial_navidad_2", photo: "https://documents.enerlence.com/f/d383dac89067498bb7c1/?dl=1"}

]

const links = {
    // aviso: { ruta: "", activePage : ""} ,
    // bienvenido: { ruta: "", activePage : ""} ,
    discrecional:{ ruta: "/discrecionales", activePage : "discrecionales"} ,
//     especial_navidad:{ ruta: "", activePage : ""} ,
//     especial_navidad_2:{ ruta: "", activePage : ""} ,
 }



const PhotoCarrousel = () => {

    const _sessionContext = useContext(sessionContext)


  const { setActivePage }  = _sessionContext

    let history = useHistory();

    const linkOnClick = image_name => {

        if (links[image_name] != "") {

            setActivePage(links[image_name].activePage)
            history.push(links[image_name].ruta)

        }

    }

    return (
        <div id="photo-carousel">

            <Carousel infiniteLoop={true} autoPlay={true} interval={6000} showThumbs={false} transitionTime={600} showStatus={false}>
                {
                    images.map((image, index) => (
                        <div onClick={() => linkOnClick(image.name)} key={index}  style={ links[image.name].ruta !== "" ? {cursor: "pointer"} : null}>
                            <img src={image.photo} alt={image.name} height={"900px"}/>

                        </div>
                    ))
                }


            </Carousel>


        </div>
    )
}

export default PhotoCarrousel;
